<template>
  <div
    v-if="value"
    v-on-clickaway="closeMenu"
    class="gl-context-menu"
    oncontextmenu="return false"
    :style="contextStyle"
  >
    <gl-menu-item
      v-if="canView"
      class="pointer mb-2"
      fullwidth
      icon="show-hidden-20"
      :icon-height="16"
      :icon-width="16"
      label="View Profile"
      @click="$emit('view', data)"
    />
    <gl-menu-item
      class="pointer mb-2"
      fullwidth
      icon="edit-16"
      :icon-height="16"
      :icon-width="16"
      label="Edit user"
      @click="$emit('edit', data)"
    />
    <gl-menu-item
      class="pointer mb-2"
      fullwidth
      icon="reset-20"
      :icon-height="20"
      :icon-width="20"
      label="Reset password"
      @click="$emit('reset-password', data)"
    />
    <hr class="grey-text">
    <gl-menu-item
      class="pointer mt-2"
      fullwidth
      icon="delete-account"
      :icon-height="16"
      :icon-width="16"
      label="Delete account"
      warn
      @click="$emit('delete', data)"
    />
  </div>
</template>

<script>
// Libs
import { mixin as clickaway } from 'vue-clickaway'
// Components
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon.vue'

export default {
  components: {
    GlIcon,
    GlMenuItem,
  },
  mixins: [clickaway],
  props: {
    position: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    canView: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contextStyle: {
        left: `${ this.position.x }px`,
        top: `${ this.position.y }px`,
      },
    }
  },
  watch: {
    position: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          const menu = this.$el
          const { x, y } = this.position
          const availableWidth = menu.parentNode.clientWidth - x
          const availableHeight = menu.parentNode.clientHeight - y
          this.contextStyle = {
            left: `${ menu.clientWidth > availableWidth ? x - menu.clientWidth : x }px`,
            top: `${ menu.clientHeight > availableHeight ? y - menu.clientHeight : y }px`,
          }
        })
      },
    },
  },
  methods: {
    closeMenu() {
      this.$emit('input', false)
    },
    editActivate() {
      this.$emit('edit-activate', this.data)
    },
    deleteActivate() {
      this.$emit('delete-activate', this.data)
    },
  },
}
</script>
